import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HomeHeader } from "@tvg/home/src/components/HomeHeader";
import { getIsLogged } from "@urp/store-selectors";
import { breakpoints, useMediaQuery } from "@tvg/design-system";
import {
  getHomepageTemplateUserPrefDesktop,
  getHomepageTemplateUserPrefMobile
} from "@tvg/sh-lib-preferences/redux/selectors";
import tvgConf from "@tvg/conf";
import {
  TopSection,
  Main,
  Side,
  HomeContainer,
  SectionsWrapper,
  Bottom
} from "./styled-components";
import componentsMapping from "./componentsMapping";
import { getHomepageTemplates } from "./store/selectors";
import { ComponentMapping, HomepageTemplate, SectionConfigType } from "./types";

export const renderComponents = (
  groupName: string,
  componentsGroups: HomepageTemplate
) => {
  if (
    !componentsGroups ||
    !componentsGroups[groupName as keyof HomepageTemplate]
  ) {
    return null;
  }

  const groupComponents = componentsGroups[groupName as keyof HomepageTemplate];

  if (!Array.isArray(groupComponents)) {
    return null;
  }

  return groupComponents
    .map((componentObj) => {
      const componentToRender = (componentsMapping as ComponentMapping)[
        componentObj.component as keyof ComponentMapping
      ]?.component;

      return componentToRender?.({ ...componentObj });
    })
    .filter(Boolean);
};

const Homepage = (): JSX.Element => {
  const templates = useSelector(getHomepageTemplates);
  const isLoggedIn = useSelector(getIsLogged);
  const isMobile =
    useMediaQuery(breakpoints.tablet.max.sm) || tvgConf().device === "mobile";
  const userPrefTemplate = useSelector(
    isMobile
      ? getHomepageTemplateUserPrefMobile
      : getHomepageTemplateUserPrefDesktop
  );

  const [defaultTemplate, setDefaultTemplate] = useState<string>("default");
  const selectedTemplate =
    userPrefTemplate.toLowerCase() || defaultTemplate || "default";

  useEffect(() => {
    if (!isLoggedIn || !userPrefTemplate) {
      setDefaultTemplate("default");
    }
  }, [userPrefTemplate, isLoggedIn]);

  const componentsGroups = templates?.[`${selectedTemplate}`];

  const render = (groupName: SectionConfigType) =>
    renderComponents(groupName, componentsGroups as HomepageTemplate);

  return (
    <HomeContainer data-qa-label="home-container">
      <TopSection data-qa-label="top-section">
        <HomeHeader />
        {render(SectionConfigType.TOP)}
      </TopSection>
      <SectionsWrapper>
        <Main data-qa-label="main-section">
          {render(SectionConfigType.MAIN)}
          <Bottom data-qa-label="bottom-section">
            {render(SectionConfigType.Bottom)}
          </Bottom>
        </Main>
        <Side data-qa-label="side-section">
          <>{render(SectionConfigType.SIDE)}</>
        </Side>
      </SectionsWrapper>
    </HomeContainer>
  );
};
export default Homepage;
