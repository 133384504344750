import { HomepageStoryblokConfig } from "../types";

export const homeStoryblokConfigMock: HomepageStoryblokConfig = {
  templates: ["expert", "default", "novice"],
  expert: {
    description: "",
    top: [
      {
        component: "QuickLinks",
        title: "",
        description: "",
        expirationDate: "2024-08-31 17:42"
      },
      {
        component: "TopRaces",
        title: "Today’s Popular Races",
        description: "",
        expirationDate: "2024-08-31 17:42"
      }
    ],
    side: [
      {
        component: "PromosWidget",
        title: "Promotions Calendar",
        description: "",
        expirationDate: "2024-08-31 17:43"
      },
      {
        component: "TopPools",
        title: "Top Pools",
        description: "",
        expirationDate: "2024-08-31 17:43"
      }
    ],
    main: [
      {
        component: "TalentPicks",
        title: "Talent Picks",
        description: "",
        expirationDate: "2024-08-31 17:42"
      },
      {
        component: "TopTracks",
        title: "Top Tracks",
        description: "",
        expirationDate: "2024-08-29 16:38"
      }
    ],
    bottom: [
      {
        title: "Horse Racing Betting",
        component: "SEOFooter",
        text: '<p>\n    <span style="font-family: Arial;">﻿</span><span style="font-family: Arial;">﻿</span><span style="font-family: Arial;">﻿</span>Welcome to <a href="/">FanDuel Racing</a>! We offer an unparalleled experience on a trustworthy platform. Our interface is easy to use. Round-the-clock races put you in the heart of the action.\n</p>\n<p>\n    Get access to:\n    </p><ul>  \n        <li>Major events</li>\n        <li>Easy deposits</li>\n        <li><a href="/promos">Exclusive promos</a></li>\n        <li>Swift payouts</li>\n    </ul>\n<p></p>\n<p>\n    Knowledge is everything! Explore:\n    </p><ul>\n        <li><a href="https://support.fanduelracing.com/s/" target="_blank">Our support site</a> for any questions</li>\n    </ul>\n<p></p>\n<p>\n    Stay engaged with:\n    </p><ul>\n        <li><a href="https://www.fanduel.com/watch" target="_blank">Live FanDuel TV coverage</a> of 300+ global racetracks</li>\n        <li><a href="/handicapping/talent-picks">Picks and previews</a> from your favorite FanDuel TV talent experts</li>\n        <li><a href="https://www.fanduel.com/research/horse-racing" target="_blank">Exclusive horse racing news</a></li>\n    </ul>\n<p></p>\n<p>\n    Get ready to bet on the Triple Crown: <a href="https://www.fanduel.com/kentucky-derby-betting" target="_blank">Kentucky Derby</a>, <a href="https://www.fanduel.com/preakness-stakes-betting" target="_blank">Preakness Stakes</a>, \n<a href="https://www.fanduel.com/belmont-stakes-betting" target="_blank">Belmont Stakes</a>, \n<a href="https://www.fanduel.com/breeders-cup-betting" target="_blank">Breeders Cup</a>.\n</p>\n<p>\n    Want to play FanDuel Racing alone? Or through <a href="https://sportsbook.fanduel.com/" target="_blank">FanDuel Sportsbook</a>?  No problem. In both apps, we blend tradition with cutting-edge technology. You’re ready for the races!\n</p>',
        expirationDate: ""
      }
    ]
  },
  default: {
    description: "",
    top: [
      {
        component: "QuickLinks",
        title: "QuickLinks",
        description: "",
        expirationDate: ""
      },
      {
        component: "TopRaces",
        title: "Today’s Popular Races",
        description: "",
        expirationDate: ""
      }
    ],
    side: [
      {
        component: "TopPools",
        title: "Top Pools",
        description: "",
        expirationDate: ""
      },
      {
        component: "PromosWidget",
        title: "Promotions Calendar",
        description: "",
        expirationDate: ""
      }
    ],
    main: [
      {
        component: "TipsAndPicks",
        title: "Tips & Picks",
        description: "",
        expirationDate: ""
      },
      {
        component: "TalentPicks",
        title: "Talent Picks",
        description: "",
        expirationDate: ""
      }
    ],
    bottom: [
      {
        title: "Horse Racing Betting",
        component: "SEOFooter",
        text: '<p>\n    <span style="font-family: Arial;">﻿</span><span style="font-family: Arial;">﻿</span><span style="font-family: Arial;">﻿</span>Welcome to <a href="/">FanDuel Racing</a>! We offer an unparalleled experience on a trustworthy platform. Our interface is easy to use. Round-the-clock races put you in the heart of the action.\n</p>\n<p>\n    Get access to:\n    </p><ul>  \n        <li>Major events</li>\n        <li>Easy deposits</li>\n        <li><a href="/promos">Exclusive promos</a></li>\n        <li>Swift payouts</li>\n    </ul>\n<p></p>\n<p>\n    Knowledge is everything! Explore:\n    </p><ul>\n        <li><a href="https://support.fanduelracing.com/s/" target="_blank">Our support site</a> for any questions</li>\n    </ul>\n<p></p>\n<p>\n    Stay engaged with:\n    </p><ul>\n        <li><a href="https://www.fanduel.com/watch" target="_blank">Live FanDuel TV coverage</a> of 300+ global racetracks</li>\n        <li><a href="/handicapping/talent-picks">Picks and previews</a> from your favorite FanDuel TV talent experts</li>\n        <li><a href="https://www.fanduel.com/research/horse-racing" target="_blank">Exclusive horse racing news</a></li>\n    </ul>\n<p></p>\n<p>\n    Get ready to bet on the Triple Crown: <a href="https://www.fanduel.com/kentucky-derby-betting" target="_blank">Kentucky Derby</a>, <a href="https://www.fanduel.com/preakness-stakes-betting" target="_blank">Preakness Stakes</a>, \n<a href="https://www.fanduel.com/belmont-stakes-betting" target="_blank">Belmont Stakes</a>, \n<a href="https://www.fanduel.com/breeders-cup-betting" target="_blank">Breeders Cup</a>.\n</p>\n<p>\n    Want to play FanDuel Racing alone? Or through <a href="https://sportsbook.fanduel.com/" target="_blank">FanDuel Sportsbook</a>?  No problem. In both apps, we blend tradition with cutting-edge technology. You’re ready for the races!\n</p>',
        expirationDate: ""
      }
    ]
  },
  novice: {
    description: "",
    top: [
      {
        component: "QuickLinks",
        title: "",
        description: "",
        expirationDate: "2024-08-31 17:43"
      },
      {
        component: "TopRaces",
        title: "Today’s Popular Races",
        description: "",
        expirationDate: "2024-08-31 17:43"
      }
    ],
    side: [
      {
        component: "PromosWidget",
        title: "Promotions Calendar",
        description: "",
        expirationDate: "2024-08-31 17:44"
      },
      {
        component: "TopPools",
        title: "Top Pools",
        description: "",
        expirationDate: "2024-08-31 17:44"
      }
    ],
    main: [
      {
        component: "TalentPicks",
        title: "Talent Picks",
        description: "",
        expirationDate: "2024-08-31 17:44"
      },
      {
        component: "TipsAndPicks",
        title: "Tips & Picks",
        description: "",
        expirationDate: "2024-08-31 17:44"
      }
    ],
    bottom: [
      {
        title: "Horse Racing Betting",
        component: "SEOFooter",
        text: '<p>\n    <span style="font-family: Arial;">﻿</span><span style="font-family: Arial;">﻿</span><span style="font-family: Arial;">﻿</span>Welcome to <a href="/">FanDuel Racing</a>! We offer an unparalleled experience on a trustworthy platform. Our interface is easy to use. Round-the-clock races put you in the heart of the action.\n</p>\n<p>\n    Get access to:\n    </p><ul>  \n        <li>Major events</li>\n        <li>Easy deposits</li>\n        <li><a href="/promos">Exclusive promos</a></li>\n        <li>Swift payouts</li>\n    </ul>\n<p></p>\n<p>\n    Knowledge is everything! Explore:\n    </p><ul>\n        <li><a href="https://support.fanduelracing.com/s/" target="_blank">Our support site</a> for any questions</li>\n    </ul>\n<p></p>\n<p>\n    Stay engaged with:\n    </p><ul>\n        <li><a href="https://www.fanduel.com/watch" target="_blank">Live FanDuel TV coverage</a> of 300+ global racetracks</li>\n        <li><a href="/handicapping/talent-picks">Picks and previews</a> from your favorite FanDuel TV talent experts</li>\n        <li><a href="https://www.fanduel.com/research/horse-racing" target="_blank">Exclusive horse racing news</a></li>\n    </ul>\n<p></p>\n<p>\n    Get ready to bet on the Triple Crown: <a href="https://www.fanduel.com/kentucky-derby-betting" target="_blank">Kentucky Derby</a>, <a href="https://www.fanduel.com/preakness-stakes-betting" target="_blank">Preakness Stakes</a>, \n<a href="https://www.fanduel.com/belmont-stakes-betting" target="_blank">Belmont Stakes</a>, \n<a href="https://www.fanduel.com/breeders-cup-betting" target="_blank">Breeders Cup</a>.\n</p>\n<p>\n    Want to play FanDuel Racing alone? Or through <a href="https://sportsbook.fanduel.com/" target="_blank">FanDuel Sportsbook</a>?  No problem. In both apps, we blend tradition with cutting-edge technology. You’re ready for the races!\n</p>',
        expirationDate: ""
      }
    ]
  }
};
