import styled from "styled-components";
import { buildColor } from "@tvg/design-system";

export const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: auto;
`;

export const SectionsWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: var(--fd-space-space-3);
  padding: var(--fd-space-space-3);
  background-color: ${buildColor("blue", "000")};
`;

export const Main = styled.div`
  grid-column: 1 / 2;
  display: flex;
  flex-direction: column;
  gap: var(--fd-space-space-3);
  overflow-x: auto;
  align-self: start;
`;

export const Side = styled.div`
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  gap: var(--fd-space-space-3);
  overflow-y: auto;
  align-self: start;
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--fd-space-space-3);
`;
