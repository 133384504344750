import { Device } from "@tvg/ts-types/Device";

export type ComponentType =
  | "QuickLinks"
  | "TopRaces"
  | "TipsAndPicks"
  | "TopPools"
  | "TalentPicks"
  | "TopTracks"
  | "PreviousWinners"
  | "PromosWidget"
  | "SEOFooter";

export enum SectionConfigType {
  DESCRIPTION = "description",
  TOP = "top",
  MAIN = "main",
  SIDE = "side",
  Bottom = "bottom"
}

export type ContentProps = {
  content?: {
    content?: {
      text?: string;
    }[];
  }[];
};

export interface ComponentProps {
  title: string;
  component: ComponentType;
  description?: string;
  expirationDate?: string;
  text?: string;
  device?: Device;
  content?: ContentProps;
}

export type HomepageSection = ComponentProps[] | string;

export interface HomeStoryblockContentResponse {
  templates: string[];
  [key: string]:
    | HomepageSection
    | ComponentType[]
    | string
    | undefined
    | string[];
}

export type ComponentMapping = {
  [key in ComponentType]: {
    component: (props: ComponentProps) => JSX.Element;
  };
};

export type HomepageTemplate = {
  [section in SectionConfigType]: HomepageSection;
};

export interface HomepageStoryblokConfig {
  templates: string[];
  [templateName: string]: HomepageTemplate | string[] | string | undefined;
}
